import React from 'react'
import {Route, Redirect} from 'react-router-dom'

const PrivateRoute = ({ component: Component, isLogged, ...rest }) => (
  <Route
    {...rest}
    render={
      props =>
        isLogged ?
          <Component {...props} /> :
          <Redirect to={{pathname: '/login', state: {from: props.location}}} />
    }
  />
)

export default PrivateRoute